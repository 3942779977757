import { useEffect } from 'react';
import { useCurrentUserId, useUserType } from '@framework/auth';
import { useGroupEntity } from '@group/hooks';
import { GroupId, GroupMemberInvitationId } from '@schema-common/base';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { Redirect } from 'react-router-dom';
import { InvitationContent } from './InvitationContent';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';

type Props = {
    groupId: GroupId;
    invitationId: GroupMemberInvitationId;
};

export const PageContent: React.FC<Props> = ({ groupId, invitationId }: Props) => {
    const currentUserId = useCurrentUserId();
    const { group } = useGroupEntity(groupId);
    const userType = useUserType();

    useEffect(() => {
        // 匿名ユーザーでログイン済みの状態であれば一度ログアウトさせる
        if (userType === 'anonymous') {
            FirebaseAuthenticationClient.signOut();
        }
    }, [userType]);

    // 参加済みグループへの招待を開いた場合は招待ページを開かずにそのグループのページにリダイレクトする
    if (currentUserId && group?.hasMemberOf(currentUserId)) {
        return <Redirect to={UserPagePathBuilder.groupPage(groupId)} />;
    }

    return <InvitationContent groupId={groupId} invitationId={invitationId} />;
};
