import { useMemo } from 'react';
import { UserIconMenu } from '@framework/ui/elements/TopNavigation';
import { GroupEntity } from '@group/domain';
import { useUserPublicProfiles } from '@user/PublicProfile';
import { SidebarOpenButton, useSidebarContext } from '@user/Sidebar';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';

type Props = {
    currentGroup: GroupEntity;
    currentUserId: string;
};

export const HEADER_HEIGHT = 64;

export const Header: React.FC<Props> = ({ currentGroup, currentUserId }: Props) => {
    const isAdminRole = currentGroup.isAdminRoleOf(currentUserId);

    const userIds = useMemo(() => currentGroup.members.userIds(), [currentGroup]);
    const userProfiles = useUserPublicProfiles(userIds);

    const { isSidebarOpenButtonVisible, toggleSidebar } = useSidebarContext();

    return (
        <div className="border-b-2">
            <div className="flex items-center" style={{ height: HEADER_HEIGHT - 2 }}>
                {isSidebarOpenButtonVisible && (
                    <div className="size-12">
                        <SidebarOpenButton onClick={toggleSidebar} />
                    </div>
                )}
                <div className="relative ml-auto flex items-center justify-items-stretch gap-x-4 p-2">
                    {isAdminRole && userProfiles && (
                        <>
                            <Link to={UserPagePathBuilder.groupConsolePage(currentGroup.id)} className="text-brand">
                                グループ管理画面
                            </Link>
                        </>
                    )}
                    <UserIconMenu userId={currentUserId} />
                </div>
            </div>
        </div>
    );
};
