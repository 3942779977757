import { FirebaseConfigType } from './type';

export const FirebaseConfig: FirebaseConfigType = {
    apiKey: 'AIzaSyCHklXdvZbJUOubsILq2TZD6Kvw8-X06Aw',
    authDomain: 'levii-balus-app.firebaseapp.com',
    databaseURL: 'https://levii-balus-app-default-rtdb.firebaseio.com',
    projectId: 'levii-balus-app',
    storageBucket: 'levii-balus-app.appspot.com',
    messagingSenderId: '638286101593',
    appId: '1:638286101593:web:39be1f82df62a15ac80548',
    measurementId: 'G-PF947JTP4Z',
};
