import { MLAPIConfigType } from './type';

export const MLAPIConfig: MLAPIConfigType = {
    outlier: 'https://balus-ml-outliers-qwhlz3ebza-an.a.run.app/',
    cluster: 'https://balus-ml-clusters-qwhlz3ebza-an.a.run.app/',
    chatgpt_cluster: 'https://balus-ml-chatgpt-clusters-qwhlz3ebza-an.a.run.app/',
    chatgpt_outlier: 'https://balus-ml-chatgpt-outliers-qwhlz3ebza-an.a.run.app/',
    chatgpt_question: 'https://balus-ml-chatgpt-questions-qwhlz3ebza-an.a.run.app/',
    chatgpt_summary: 'https://balus-ml-chatgpt-summary-qwhlz3ebza-an.a.run.app/',
    chatgpt_modeling: 'https://balus-ml-chatgpt-modeling-qwhlz3ebza-an.a.run.app/',
    chatgpt_similar_node: 'https://balus-ml-chatgpt-similar-node-qwhlz3ebza-an.a.run.app/?key=',
    chatgpt_zone_title: 'https://balus-ml-chatgpt-zone-title-qwhlz3ebza-an.a.run.app/?key=',
};
