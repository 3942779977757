import { UserKey } from '@user/domain';
import { GroupMemberRole } from './GroupMemberRole';
import { GroupJSON } from '@schema-app/groups/{groupKey}/GroupJSON';

type GroupMemberRoleRecord = Record<string, GroupMemberRole>;

export class GroupMemberRoles {
    private readonly memberRoles: Readonly<GroupMemberRoleRecord>;
    constructor(memberRoles: GroupMemberRoleRecord) {
        this.memberRoles = { ...memberRoles };
    }

    dump(): GroupJSON['members'] {
        return { ...this.memberRoles };
    }

    static load(dump: Record<string, string> | null): GroupMemberRoles {
        return this.fromEntries(Object.entries(dump || {}));
    }

    static fromEntries(entries: [string, string][]): GroupMemberRoles {
        const memberRoles: GroupMemberRoleRecord = {};
        for (const [userKey, role] of entries) {
            memberRoles[userKey] = GroupMemberRole.load(role);
        }
        return new this(memberRoles);
    }

    get length(): number {
        return Object.keys(this.memberRoles).length;
    }

    isAdminRoleOf(userKey: UserKey): boolean {
        return GroupMemberRole.isAdmin(this.getRoleOf(`${userKey.id}`));
    }

    getRoleOf(userId: string): GroupMemberRole | undefined {
        return this.memberRoles[`User:${userId}`];
    }

    userIds(): string[] {
        return Object.keys(this.memberRoles).map((key) => key.replace('User:', ''));
    }
}
