import { GroupEntity, GroupMemberRole } from '@group/domain';
import { UserData } from '@user/UserData';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FullScreenLoading } from '@framework/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { useCurrentSessionId, useCurrentUserId } from '@framework/auth/AuthContext';
import { KeysRepository, ObjectRepository, RTDBPath } from '@framework/repository';
import { UserId, WorkspaceId } from '@schema-common/base';
import { Workspace, WorkspaceMemberRole } from '@workspace/domain/workspace';
import { generateDefaultIcon } from '@framework/utils';
import { DeleteWorkspaceMemberButton } from './DeleteWorkspaceMemberButton';
import { WorkspaceMemberDeleteConfirmModal } from './WorkspaceMemberDeleteConfirmModal';
import { useAssignedWorkspaces } from '@workspace/ui/WorkspaceList';
import { getMemberDataViaGroupConsoleUsing } from '../common/getMemberDataViaGroupConsoleUsing';

type Props = {
    group: GroupEntity;
};

const canDeleteMember = (
    currentUserId: UserId | null,
    memberId: UserId,
    workspace: Workspace,
    internalWorkspaceIds: WorkspaceId[]
): boolean => {
    // グループ管理者自身については、ワークスペースから削除できない
    if (currentUserId === memberId) return false;

    // グループ内公開ワークスペース、もしくはパーソナルワークスペースからは削除できない
    if (internalWorkspaceIds.includes(workspace.id) || workspace.isPersonal) return false;

    // 対象となるメンバーがワークスペースの唯一の管理者である場合は削除できない
    if (
        workspace.getMemberRoleOf(memberId) === 'admin' &&
        workspace.memberRoles.userIdsByRole(WorkspaceMemberRole.admin).length <= 1
    )
        return false;

    return true;
};

export const GroupConsoleMemberPage: React.FC<Props> = ({ group }: Props) => {
    const { memberId } = useParams<{ memberId: string }>();
    const sessionId = useCurrentSessionId();
    const [memberData, setMemberData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState(true);
    const [deleteTargetWorkspace, setDeleteTargetWorkspace] = useState<Workspace | null>(null);
    const [internalWorkspaceIds, setInternalWorkspaceIds] = useState<WorkspaceId[]>([]);
    const assignedWorkspaces = useAssignedWorkspaces(memberId || null, group.id);
    const currentUserId = useCurrentUserId();

    // メンバーデータの取得
    useEffect(() => {
        if (!sessionId || !memberId) return;

        const getMemberData = async () => {
            const memberDataRepo = new ObjectRepository(UserData, RTDBPath.User.userEntityPath(memberId));

            return await getMemberDataViaGroupConsoleUsing(group.id, sessionId, () => memberDataRepo.get());
        };

        getMemberData()
            .then((memberData) => {
                setMemberData(memberData);
            })
            .finally(() => setLoading(false));
    }, [memberId, sessionId, group.id]);

    // グループ内公開ワークスペースの一覧取得
    useEffect(() => {
        if (!memberData) return;
        const internalWorkspaceIdsRepo = new KeysRepository(RTDBPath.Group.internalPublicWorkspacesPath(group.id));

        internalWorkspaceIdsRepo.get().then((workspaceIds) => {
            setInternalWorkspaceIds(workspaceIds);
        });
    }, [memberData, group]);

    const memberRole = useMemo(() => {
        if (!memberData) return null;
        return group.members.getRoleOf(memberData.id) || null;
    }, [group, memberData]);

    const handleCloseDeleteModal = useCallback(() => {
        setDeleteTargetWorkspace(null);
    }, []);

    if (loading) return <FullScreenLoading />;

    return (
        <>
            <Link className="mb-4 flex items-center" to={UserPagePathBuilder.groupConsoleMembersPage(group.id)}>
                <FontAwesomeIcon icon={faAngleLeft} className="text-brand" />
                <span className="text-brand">メンバー</span>
            </Link>

            {memberData ? (
                <>
                    <div className="mb-4 flex size-10 items-center">
                        <UserIcon
                            name={memberData.name}
                            id={memberData.id}
                            imageUrl={memberData.imageUrl}
                            size="large"
                            className="mr-2 grow-0"
                        />
                        <span className="shrink-0">{memberData.name}</span>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2 font-bold">メールアドレス</div>
                        <div>{memberData.mailAddress}</div>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2 font-bold">ロール</div>
                        <div>{memberRole ? GroupMemberRole.getLocalString(memberRole) : 'なし'}</div>
                    </div>

                    <div className="mb-2 font-bold">参加ワークスペース</div>
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(100% - 275px)' }}>
                        {!assignedWorkspaces || assignedWorkspaces.length <= 0 ? (
                            <div>参加しているワークスペースはありません</div>
                        ) : (
                            assignedWorkspaces.map((workspace) => (
                                <div className="flex items-center border-b-2 py-2 first:border-t-2" key={workspace.id}>
                                    {workspace.imageUrl ? (
                                        <img
                                            className="mr-2 size-10 overflow-hidden rounded-full"
                                            src={workspace.imageUrl}
                                            alt={workspace.name.value}
                                        />
                                    ) : (
                                        <div className="mr-2 size-10 overflow-hidden rounded-full">
                                            {generateDefaultIcon({
                                                id: workspace.id,
                                                name: workspace.name.value,
                                                size: 40,
                                            })}
                                        </div>
                                    )}
                                    <Link
                                        className="mr-auto font-bold text-brand underline"
                                        to={UserPagePathBuilder.workspacePage(workspace.id)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {workspace.name.value}
                                    </Link>
                                    {canDeleteMember(currentUserId, memberData.id, workspace, internalWorkspaceIds) && (
                                        <DeleteWorkspaceMemberButton
                                            workspace={workspace}
                                            onDelete={setDeleteTargetWorkspace}
                                        />
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </>
            ) : (
                <div>メンバーがいません</div>
            )}
            {deleteTargetWorkspace && memberData && (
                <WorkspaceMemberDeleteConfirmModal
                    isOpen={!!deleteTargetWorkspace}
                    memberData={memberData}
                    workspace={deleteTargetWorkspace}
                    onClose={handleCloseDeleteModal}
                />
            )}
        </>
    );
};
