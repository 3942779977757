import { GroupMemberRoleJSON } from '@schema-common/group';

export type GroupMemberRole = 'admin' | 'member';
const FallbackRole = 'member'; // 未知の値の場合には、一番弱い権限のロールにフォールバックする

// FIXME: グループメンバーのロールごとに、操作可能な内容を整理する。
// いったんグループ情報の read / write の区別のみを記載しているが、グループ情報の閲覧・編集や、メンバーの招待・変更など、
// 操作種類ごとに permission を列挙すること。
type GroupPermission = {
    read: boolean;
    write: boolean;
};

const rolePermissions: Record<GroupMemberRole, GroupPermission> = {
    admin: {
        read: true,
        write: true,
    },
    member: {
        read: true,
        write: false,
    },
};

const roleList = Object.keys(rolePermissions) as GroupMemberRole[];

const dict: Record<GroupMemberRole, string> = {
    admin: '管理者',
    member: 'メンバー',
};

export namespace GroupMemberRole {
    export function values(): GroupMemberRole[] {
        return roleList.concat();
    }

    export function load(value: string): GroupMemberRole {
        return GroupMemberRole.values().find((role) => role === value) || FallbackRole;
    }

    export function dump(role: GroupMemberRole): string {
        return role.toString();
    }

    export function isAdmin(role: string | undefined | null): boolean {
        return role === 'admin';
    }

    export function getLocalString(status: GroupMemberRole): string {
        return dict[status];
    }
}

export const isGroupMemberRole = (value: string): value is GroupMemberRoleJSON => {
    return value in dict;
};
