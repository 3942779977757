import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { RecordRepository, RefBuilder, RTDBPath, UpdateUtil } from '@framework/repository';
import { GroupMemberInvitation } from '@group/domain';
import { GroupId, NO_ASSIGN, UserId } from '@schema-common/base';
import { pick } from 'lodash';

/**
 * グループメンバー招待処理をUIから呼び出す際のエントリーポイント。
 *
 * 呼び出される際には成功する条件が揃った状態で呼び出されることを前提にする。
 * 失敗する時は例外的な状況なので、成功失敗のフィードバックは詳細をエンドユーザーには返さず、エラー検知システム側にだけフィードバックする。
 */
export class GroupMemberInvitationOperation {
    /**
     * グループメンバーの招待状を無効化します。
     * @param invitation 招待情報
     * @return 成功したかどうかを返します。
     */
    static async deactivate(invitation: GroupMemberInvitation): Promise<boolean> {
        return new Promise((resolve) => {
            const ref = RefBuilder.ref(RTDBPath.Group.memberInvitationPath(invitation.groupId, invitation.id));

            ref.child('active').set(false, (err) => {
                if (err) console.error(err);
                resolve(!err);
            });
        });
    }

    /**
     * 指定のグループに対する全てのグループメンバーの招待状を無効化します。
     * グループを論理削除する際に呼び出すことを想定しています。
     * @param groupId グループID
     * @returns 成功したかどうかを返します。
     */
    static async allDeactivate(groupId: GroupId): Promise<boolean> {
        const repo = new RecordRepository(GroupMemberInvitation, RTDBPath.Group.memberInvitationsPath(groupId));
        const invitations = Object.values(await repo.get());

        // 現時点で招待受諾待ちの招待状を取り出す
        const pendingInvitations = invitations.filter((invitation) => invitation.isPending());

        const results = await Promise.all(pendingInvitations.map((invitation) => this.deactivate(invitation)));
        return results.every((value) => value);
    }

    /**
     * 招待状を元にユーザーをグループに招待します。
     *
     * 招待状が承諾されていなければ先に招待状の承諾処理を行い、それからグループへの招待処理を行います。
     *
     * @param userId 参加対象のユーザーのID
     * @param invitation 招待状
     *
     * @return グループの参加まで成功した場合はtrue, そうでない場合はfalseを返します。
     */
    static async inviteUser(userId: UserId, invitation: GroupMemberInvitation): Promise<boolean> {
        if (invitation.isAccepted()) return this.inviteUserByAcceptedInvitation(invitation);

        const acceptedInvitation = await this.acceptGroupMemberInvitation(userId, invitation);
        if (!acceptedInvitation) return false;

        return this.inviteUserByAcceptedInvitation(acceptedInvitation);
    }

    /**
     * 招待の承諾処理を指定されたユーザーによって行います。
     *
     * 承諾処理の内容:
     *
     * - 招待状の状態更新（承諾日時および承諾ユーザーの更新、招待状の無効化）
     * - 招待承諾データの作成（別ステップのグループ参加に利用する）
     *
     * @param userId 承諾対象のユーザー
     * @param invitation 未承諾の招待状
     * @return 成功した場合は承諾済みの招待状、そうでない場合はnullを返します。
     * @private
     */
    private static async acceptGroupMemberInvitation(
        userId: UserId,
        invitation: GroupMemberInvitation
    ): Promise<GroupMemberInvitation | null> {
        const { groupId, id } = invitation;
        const now = await getRealtimeDatabaseCurrentTimestamp();
        const acceptedInvitation = invitation.accept(userId, now);

        const updates = {
            [RTDBPath.Group.memberInvitationAcceptPath(groupId, userId)]: id,
            // updateが部分適用されるようにprependPath()でレコードをフラット化する
            ...UpdateUtil.prependPath(
                RTDBPath.Group.memberInvitationPath(groupId, id),
                pick(acceptedInvitation.dump(), ['acceptedAt', 'acceptedUserId'])
            ),
        };

        return new Promise((resolve) => {
            RefBuilder.ref().update(updates, (err) => {
                if (!err) {
                    resolve(acceptedInvitation);
                } else {
                    console.error(err);
                    resolve(null);
                }
            });
        });
    }

    /**
     * 承諾済みの招待状を元にユーザーをグループに招待します。
     *
     * @param acceptedInvitation 承諾済みの招待状
     * @private
     */
    private static async inviteUserByAcceptedInvitation(acceptedInvitation: GroupMemberInvitation): Promise<boolean> {
        const {
            acceptedUserId,
            groupId,
            invitation: { memberRole },
        } = acceptedInvitation;

        // null許容なデータ型のため、アサート的なチェックをしてnull除外する
        if (!acceptedUserId) {
            throw Error(`invalid invitation (groupId=${groupId}, invitationId=${acceptedInvitation.id})`);
        }

        const updates = {
            [RTDBPath.Group.memberInvitationAcceptPath(groupId, acceptedUserId)]: null,
            [RTDBPath.Group.memberPath(groupId, acceptedUserId)]: memberRole,
            [RTDBPath.Group.memberRolePath(groupId, acceptedUserId)]: memberRole,
            [RTDBPath.Group.assignedGroupIndexValuePath(acceptedUserId, groupId)]: NO_ASSIGN,
        };

        return new Promise((resolve) => {
            RefBuilder.ref().update(updates, (err) => {
                if (err) console.error(err);
                resolve(!err);
            });
        });
    }
}
